<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
    <el-drawer v-model="formDrawerVisible2" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible2" style="padding-left: 16px; padding-right: 16px;">
        <formSee :columns="addColumnsDet" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { schoolKinList, schoolMoneyDel, schoolMoneyList, schoolMoneySubmit } from '@/apis/common'
import ListToolbar from '@/components/system/listToolbar.vue'
import listTable from '@/components/system/listTable.vue'
import Form from '@/components/system/form.vue'
import formSee from '@/components/system/formSee.vue'

export default {
  name: 'income',
  components: { formSee, Form, listTable, ListToolbar },
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      formData: {
        type: '',
        kind_id: '',
        start_date: '',
        end_date: ''
      },
      toolbar: [
        {type: 'select', label: '类型', model: 'type', value: '', options: [
          {label: '全部', value: ''},
          {label: '收入', value: 1},
          {label: '支出', value: 2}
        ]},
        {type: 'select', label: '明细', model: 'kind_id', value: '', options: []},
        {type: 'date', label: '开始时间', model: 'start_date', value: ''},
        {type: 'date', label: '结束时间', model: 'end_date', value: ''},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      typeForm: 'add',
      formDrawerVisible: false,
      formDrawerVisible2: false,
      addColumns: [
        {label: '明细', prop: 'kind_id', type: 'select', value: '',options: []},
        {label: '分类', prop: 'type', type: 'select', value: '',options: [
          {label: '收入', value: 1},
          {label: '支出', value: 2}
        ]},
        {label: '时间', prop: 'date', type: 'date', value: ''},
        {label: '金额', prop: 'money', type: 'input', value: ''},
        {label: '备注', prop: 'note', type: 'textarea', value: ''},
      ],
      list: [],
      columns: [
        {label: '记录ID', prop: 'id', width: 100, type: 'text'},
        {label: '明细名称', prop: 'kind_id', width: 100, type: 'json', deputy: 'kind'},
        {label: '分类', prop: 'type', width: 100, type: 'tag', arr: [{key: 1,type: 'success', label: '收入'}, {key: 2,type: 'success', label: '支出'}]},
        {label: '金额', prop: 'money', width: 100, type: 'text'},
        {label: '日期', prop: 'date', width: 100, type: 'text'},
        {label: '备注', prop: 'note', width: 200, type: 'text'},
        {label: '操作', width: 260, type: 'but', arr: [
            {type: 'default', icon: 'el-icon-document', label: '查看', onClick: (row) => this.seeDetails(row)},
            {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
            {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      addFormData: {},
      addColumnsDet: [
        {label: '明细', prop: 'kind', type: 'tag', value: '',name: 'name'},
        {label: '分类', prop: 'type', type: 'select', value: '',options: [
          {label: '收入', value: 1},
          {label: '支出', value: 2}
        ]},
        {label: '金额', prop: 'money', type: 'input', value: ''},
        {label: '日期', prop: 'date', type: 'date', value: ''},
        {label: '创建时间', prop: 'created_at', type: 'date', value: ''},
        {label: '备注', prop: 'note', type: 'input', value: ''},
      ]
    }
  },
  mounted() {
    this.getSchoolMoneyList()
    this.getSchoolKinList()
  },
  methods: {
    // 查看
    seeDetails(row) {
      this.addColumnsDet.forEach(item => {
        item.value = row[item.prop]
      })
      this.formDrawerVisible2 = true
    },
    // 删除
    del(row) {
      this.addFormData = row
      this.$confirm('确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        schoolMoneyDel({
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          money_id: row.id
        }).then(res => {
          this.$message.success('删除成功')
          this.getSchoolMoneyList()
        })
      }).catch(() => {})
    },
    // 修改
    modify(row) {
      this.addFormData = row
      this.typeForm = 'edit'
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
      })
      this.formDrawerVisible = true
    },
    submit(e,n) {
      let form = {
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        money_id: n === 'edit' ? this.addFormData.id : '',
        ...e
      }
      schoolMoneySubmit(form).then(res => {
        this.formDrawerVisible = false
        this.$message.success('操作成功')
        this.getSchoolMoneyList()
      })
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    // 分页
    pageChange(e) {
      this.page = e
      this.getSchoolGoodsList()
    },
    toolTap(data) {
      this.formData = data
      this.page = 1
      this.getSchoolMoneyList()
    },
    getSchoolKinList() {
      schoolKinList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
      }).then(res => {
        this.toolbar[1].options = res.data.data.map(x => {
          return {label: x.name, value: x.id}
        })
        this.addColumns[0].options = res.data.data.map(x => {
          return {label: x.name, value: x.id}
        })
      })
    },
    getSchoolMoneyList() {
      this.loading = true
      schoolMoneyList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        page: this.page,
        pagesize: this.pageSize,
        ...this.formData
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
